import React from 'react'

function ProjectSilks() {
    return (
        <>
            <h1 className="projectsSilks">Projects - Silks Website</h1>
        </>
    )
}

export default ProjectSilks
