import React, { useState } from "react";
import './ContactForm.css';
import Section from "../Section/Section";

const ContactForm = ({ email }) => {
	const [formData, setFormData] = useState({
		topic: "General Inquiry", // Default topic
		message: "",
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const subject = encodeURIComponent(formData.topic);
		const body = encodeURIComponent(formData.message);
		const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;

		window.location.href = mailtoLink; // Opens user's email client
	};

	return (
		<Section className='contact-form-section'>
			<form onSubmit={handleSubmit}>
			<div className="topic-select">
				<label htmlFor="topic">Select a Topic:</label>
				<select
				id="topic"
				name="topic"
				value={formData.topic}
				onChange={handleChange}
				required
				>
				<option value="General Inquiry">General Inquiry</option>
				<option value="Support Request">Support Request</option>
				<option value="Feedback">Feedback</option>
				</select>
			</div>

			<div className="message-area">
				<label htmlFor="message">Your Message:</label>
				<textarea
				id="message"
				name="message"
				value={formData.message}
				onChange={handleChange}
				required
				></textarea>
			</div>

			<button className="contact-submit" type="submit">Send Email</button>
			</form>
		</Section>
	);
};

export default ContactForm;
