import React from 'react'

function ServicesAI() {
    return (
		<>
			<h1 className="servicesAI">SERVICES - AI</h1>
		</>
    )
}

export default ServicesAI
