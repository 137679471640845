import React from 'react'

function ProjectBujt() {
    return (
        <>
            <h1 className="projectsBujt">Projects - Bujt</h1>
        </>
    )
}

export default ProjectBujt
