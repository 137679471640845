import React from 'react';
import { useInView } from 'react-intersection-observer';
import Section from '../Section/Section';
import './TextSection.css';

function TextSection({ 
  title, 
  darkBackground = false,
  heading, 
  bodyCopy, 
  button 
}) { 
  // Create in-view hooks for each text element
  const { ref: titleRef, inView: titleInView } = useInView({ triggerOnce: true, threshold: 0.8 });
  const { ref: headingRef, inView: headingInView } = useInView({ triggerOnce: true, threshold: 0.8 });
  const { ref: bodyCopyRef, inView: bodyCopyInView } = useInView({ triggerOnce: true, threshold: 0.8 });

  return (
    <Section className={`text-section ${darkBackground ? 'dark' : 'light'}`}>
      {title && (
        <h2
          ref={titleRef}
          className={`fade-in ${titleInView ? 'in-view' : ''}`}
        >
          {title}
        </h2>
      )}
      {heading && (
        <h3
          ref={headingRef}
          className={`fade-in ${headingInView ? 'in-view' : ''}`}
        >
          {heading}
        </h3>
      )}
      {bodyCopy && (
        <p
          ref={bodyCopyRef}
          className={`fade-in ${bodyCopyInView ? 'in-view' : ''}`}
        >
          {bodyCopy}
        </p>
      )}
      {button && (button)}
    </Section>
  );
}

export default TextSection;
