import React from 'react'
import ContactForm from '../ContactForm/ContactForm'
import HeroSection from '../HeroSection/HeroSection'
import TextSection from '../TextSection/TextSection'

function Contact() {
	return (
		<>
			<HeroSection title='Contact Us' subTitle={`We're here to help :)`}imageUrl={'/images/ApidaeContactUs1.png'}></HeroSection>
			<TextSection darkBackground={true} title="Hello... Hi.." bodyCopy={`
			Have you got that next million-dollar app idea burning a hole in your notebook?
			Need a sleek, responsive website that not only looks incredible but truly reflects your brand's personality?
			Curious about how AI models can revolutionize your business workflow, saving time and boosting productivity?
			Love the idea of crafting bespoke, boutique software solutions that feel as unique as your business?
			We're always keen to chat. Whether you're brainstorming, planning, or ready to dive in, we're here to help turn your ideas into reality. Reach out today—your next big project is just a conversation away!
			`}/>
			<ContactForm email={'allenruaidhri@gmail.com'}></ContactForm>
		</>
	)
}

export default Contact
