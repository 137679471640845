import React from 'react'
import HeroSection from '../HeroSection/HeroSection'
import TextSection from '../TextSection/TextSection'
import { Button } from '../Button/Button'

function About() {

    const contactButton = (
        <Button
            buttonStyle="btn--outline"
            buttonSize="btn--medium"
            url="/contact"
        > Contact Us
        </Button>
    )

    return (
        <>
            <HeroSection
                title="About Us"
                subTitle="WE LOVE CODE"
                imageUrl="/images/web-development.png"
            />
            <TextSection
                title='Our Mission'
                darkBackground={true}
                bodyCopy={`At Apidae, we build elegant, handcrafted digital solutions tailored to boutique brands. Like the bee, we value interconnection and strive to create meaningful, impactful software ecosystems, one line of code at a time.`}
            />
            <TextSection
                title='Our Story'
                darkBackground={false}
                bodyCopy={`Founded in 2020, Apidae was born from a passion for creating from scratch, embracing the ethos of craftsmanship in every line of code. With years of experience in Swift development and the Apple ecosystem, we help boutique brands flourish through bespoke digital solutions.`}
            />
            <TextSection
                title='Our Services'
                darkBackground={true}
                bodyCopy={<ul>
                    <li>Custom app development (iOS, macOS, watchOS, tvOS).</li>
                    <li>Bespoke web portals (Next.js, React, Firebase).</li>
                    <li>Niche expertise like D&D tools, eco-friendly applications, and financial tracking apps.</li>
                </ul>}
            />
            <TextSection
                title='Our Approach'
                darkBackground={false}
                bodyCopy={"Every project starts with a conversation. We listen, brainstorm, and collaborate with you to ensure every pixel and every function reflects your brand's unique identity."}
            />
            <TextSection
                title='Our Team'
                darkBackground={true}
                heading='Rory'
                bodyCopy={`This idiot loves Dungeons&Dragons, breakbeats, and well architected code. He's been programming since he was a wee bub and you can usually find him poring over Swift code or knee-deep in JavaScript. Fun Fact: 'Groz' is not his legitimate middle name`}
            />
            <TextSection
                darkBackground={true}
                heading='Others?'
                bodyCopy={`We are always looking to colaborate. If you love designing things or building things with code (or if you think you would be good at either of those things), don't hesitate to reach out to us.`}
                button={contactButton}
            />
        </>
    )
}

export default About
