import React from 'react'

function ProjectRadPM() {
    return (
        <>
            <h1 className="projectsRadPM">Projects - Rad P.M.</h1>
        </>
    )
}

export default ProjectRadPM
