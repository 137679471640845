import React from 'react'

function ServicesMobile() {
    return (
		<>
			<h1 className="servicesMobile">SERVICES - APP</h1>
		</>
    )
}

export default ServicesMobile
