import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

import Projects from './components/pages/Projects';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import About from './components/pages/About';
import Contact from './components/pages/Contact';

import ServicesWeb from './components/pages/Services/ServicesWeb';

import ProjectRadPM from './components/pages/Projects/ProjectRadPM';
import ProjectThisSite from './components/pages/Projects/ProjectThisSite';
import ProjectSilks from './components/pages/Projects/ProjectSilks';
import ProjectAntsteroids from './components/pages/Projects/ProjectAntsteroids';
import ProjectInvokeTheWarlock from './components/pages/Projects/ProjectInvokeTheWarlock';
import ProjectIllTechnique from './components/pages/Projects/ProjectIllTechnique';
import ServicesMobile from './components/pages/Services/ServicesMobile';
import ServicesCloud from './components/pages/Services/ServicesCloud';
import ServicesAI from './components/pages/Services/ServicesAI';
import ProjectBujt from './components/pages/Projects/ProjectBujt';
import ProjectBustaBlox from './components/pages/Projects/ProjectBustaBlox';
import ProjectSamijz from './components/pages/Projects/ProjectSamijz';
import ProjectPipsWeirdMirror from './components/pages/Projects/ProjectPipsWeirdMirror';

function App() {
	return (
		<Router>
			<Navbar />
			<Routes>
				<Route path='/' exact Component={Home}/>
				<Route path='/services' exact Component={Services}/>
				<Route path='/projects' exact Component={Projects}/>
				<Route path='/about' exact Component={About}/>
				<Route path='/contact' exact Component={Contact}/>

				<Route path='/services/web' exact Component={ServicesWeb}/>
				<Route path='/services/mobile' exact Component={ServicesMobile}/>
				<Route path='/services/cloud' exact Component={ServicesCloud}/>
				<Route path='/services/ai' exact Component={ServicesAI}/>

				<Route path='/projects/antsteroids' exact Component={ProjectAntsteroids}/>
				<Route path='/projects/invokeTheWarlock' exact Component={ProjectInvokeTheWarlock}/>
				<Route path='/projects/illTechnique' exact Component={ProjectIllTechnique}/>
				<Route path='/projects/radPM' exact Component={ProjectRadPM}/>
				<Route path='/projects/thisSite' exact Component={ProjectThisSite}/>
				<Route path='/projects/silks' exact Component={ProjectSilks}/>
				<Route path='/projects/bujt' exact Component={ProjectBujt}/>
				<Route path='/projects/bustaBlox' exact Component={ProjectBustaBlox}/>
				<Route path='/projects/samijz' exact Component={ProjectSamijz}/>
				<Route path='/projects/pipsWeirdMirror' exact Component={ProjectPipsWeirdMirror}/>
			</Routes>
			<Footer />
		</Router>
	);
}

export default App;
