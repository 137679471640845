import React from 'react'

function ServicesWeb() {
	return (
		<>
			<h1 className="servicesWeb">SERVICES - WEB</h1>
		</>
	)
}

export default ServicesWeb
