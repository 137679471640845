// import React from 'react';
// import './Section.css';

// const Section = ({
//   className = '',
//   style = {},
//   children,
//   id,
//   tag: Tag = 'section',
// }) => {
//   return (
//     <Tag id={id} className={`section ${className}`} style={style}>
//       {children}
//     </Tag>
//   );
// };

// export default Section;

import React, { forwardRef } from 'react';
import './Section.css';

const Section = forwardRef(
  ({ className = '', style = {}, children, id, tag: Tag = 'section' }, ref) => {
    return (
      <Tag id={id} className={`section ${className}`} style={style} ref={ref}>
        {children}
      </Tag>
    );
  }
);

export default Section;
