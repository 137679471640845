import React from 'react'

function Services() {
    return (
        <>
            <h1 className="services">SERVICES</h1>
        </>
    )
}

export default Services
