import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Navigation Links */}
        <div className="footer-nav-links">
          <a href="/" className="footer-link">
            Home
          </a>
          <a href="/about" className="footer-link">
            About
          </a>
          <a href="/services" className="footer-link">
            Services
          </a>
          <a href="/contact" className="footer-link">
            Contact
          </a>
        </div>

        {/* Social Media Links */}
        <div className="footer-social-links">
          <a href="/contact" className="footer-icon">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="/contact" className="footer-icon">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="/contact" className="footer-icon">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="/contact" className="footer-icon">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
      <div className="footer-bottom">
        &copy; {new Date().getFullYear()} Apidae. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
