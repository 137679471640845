import React from 'react';
import HeroSection from '../HeroSection/HeroSection';
import CardSection from '../CardSection/CardSection';
import ImageSection from '../ImageSection/ImageSection';
import TextSection from '../TextSection/TextSection';
import InfoSection from '../InfoSection/InfoSection';
import { Button } from '../Button/Button';

function Home() {
    const serviceCards = [
        { title: 'Web Application Development', description: 'From simple, single-page websites to distributed enterprise applications, I can tailor a development project to any scale you require', image: '/images/web-development.png', link: '/services/web' },
        { title: 'Mobile App Development', description: 'Native applications on both iOS and Android are my bread and butter. I deliver sophisticated mobile solutions which utilise the fastest and most robust technologies available', image: '/images/app-development.png', link: '/services/mobile' },
        { title: 'Cloud Infrastructure Deployment', description: 'I am an AWS certified cloud practisioner. I can help your business leverage the benefits of highly-scalable distributed data solutions', image: '/images/cloud-development.png', link: '/services/cloud' },
        { title: 'Artificial Intelligence', description: 'I have extensive experience training AI models and harnessing their power to build more efficient business workflows.', image: '/images/ai-development.png', link: '/services/ai' }
    ];

    const projectCards = [
        { title: 'Antsteroids!', description: 'A high-octane space shooter for iOS', image: '/images/AntsteroidsCap1.png', link: '/projects/antsteroids' },
        { title: 'Invoke The Warlock', description: 'An NPC generator for everyones favourite 5th edition pencil-and-paper RPG', image: '/images/InvokeTheWarlock.png', link: '/projects/invokeTheWarlock' },
        { title: 'Ill Technique', description: 'An iOS App for freestyle emcees', image: '/images/ITDarkLogo1024.png', link: '/projects/illTechnique' },
        { title: 'Rad P.M.', description: 'A no-nonsense project management solution', image: '/images/RadPM1.png', link: '/projects/radPM' },
        { title: 'This Site', description: `Woah, spooky?! That's some kinda Inception, or summink?`, image: '/images/ApidaeSite1.png', link: '/projects/thisSite' },
        { title: 'Silks', description: 'A cool website for a cool bicycle mechanic', image: '/images/Silks1.png', link: '/projects/silks' },
        { title: 'Bujt', description: 'User friendly budgeting App for those who hate budgeting', image: '/images/Bujt1.png', link: '/projects/bujt' },
        { title: 'Busta Blox', description: 'Pong... but for 1 player... but turbo...', image: '/images/BustaBlox1.png', link: '/projects/bustaBlox' },
        { title: 'Samijz', description: 'Mmm :p A Samij!', image: '/images/Samijz1.png', link: '/projects/samijz' },
        { title: `Pip's Weird Mirror`, description: 'A mirror on your phone... no, not your camera... weirder', image: '/images/PipsWeirdMirror1.png', link: '/projects/pipsWeirdMirror' }
    ];

	const letsConnectButton = (
        <Button
            buttonStyle="btn--outline"
            buttonSize="btn--medium"
            url="/contact"
        >Let's Connect!
        </Button>
    )

    return (
        <>
			<HeroSection
				title="Artisan Software"
				subTitle={`HANDCRAFTED WITH \u2665`}
				imageUrl='/images/AntsteroidsCap1.png'
			/>
			<InfoSection darkBackground={true} 
				imageOnRight={true}  
				heading={`What is Apidae.Tech?`}
				button={letsConnectButton}
				media={{type:'image', src:'/images/PlantAndroid1.png', alt:"alt"}}
			><p>It's just me, Rory. I'm a freelance software engineer.</p>
			<p>I love to code and nothing makes me happier than building Apps and Websites
			and putting them out in the world for people to use and enjoy.</p>
			<p>At the mome, <em>Apidae.Tech</em> serves multiple purposes:</p>
			<ol>
				<li>You can find out which services I offer to see if they align with your project.</li>
				<li>You can check out details of each of the the projects I've been working on.</li>
				<li>You can connect with me to discuss how I might help you build out a technology solution.</li>
			</ol>
			<p>Please don't hesitate to reach out if you think I might be a good addition to your project. 
				I'm always keen to connect with new people and work on cool stuff</p>
			</InfoSection>
			<TextSection title='How I do, what I do' bodyCopy={`My philosophy is simple: build software like art - crafted with care, precision, and individuality. I tailor each solution to your unique vision.`}/>
			<CardSection title="My Services" cards={serviceCards} darkBackground={true}/>
			<ImageSection imageUrl='/images/SpiderHacker1.png' quote='i love this quote: Rory Allen'></ImageSection>
			<CardSection title="My Projects" cards={projectCards}/>
        </>
    )
}

export default Home
