// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Button}  from '../Button/Button';
// import'./Navbar.css';


// function Navbar() {
//     const [click, setClick] = useState(false);
//     const [button, setButton] = useState(true)

//     const handleClick = () => setClick(!click);
//     const closeMobileMenu = () => setClick(false);

//     const showButton = () => {
//         if(window.innerWidth <= 960) {
//             setButton(false)
//         } else {
//             setButton(true)
//         }
//     };

//     useEffect(() => {
//         showButton();
//     }, []);

//     window.addEventListener('resize', showButton)

//     return (
//         <>
//             <nav className="navbar">
//                 <div className="navbar-container">
//                     <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
//                         Apidae <i className="fa-solid fa-code"></i>
//                     </Link>
//                     <div className="menu-icon" onClick={handleClick}>
//                         <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
//                     </div>
//                     <ul className={click ? 'nav-menu active' : "nav-menu"}>
//                         <li className='nav-item'>
//                             <Link to='/' className='nav-links' onClick={closeMobileMenu}>
//                                 Home
//                             </Link>
//                         </li>
//                         <li className='nav-item'>
//                             <Link to='/services' className='nav-links' onClick={closeMobileMenu}>
//                                 Services
//                             </Link>
//                         </li>
//                         <li className='nav-item'>
//                             <Link to='/products' className='nav-links' onClick={closeMobileMenu}>
//                                 Products
//                             </Link>
//                         </li>
//                         <li className='nav-item'>
//                             <Link to='/sign-up' className='nav-links-mobile' onClick={closeMobileMenu}>
//                                 Sign Up
//                             </Link>
//                         </li>
//                     </ul>
//                     {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}
//                 </div>
//             </nav>
//         </>
//     )
// }

// export default Navbar

import React, { useState} from 'react'
import { MenuItems } from './MenuItems'
import './Navbar.css'
import { Link } from 'react-router-dom';
import { Button } from '../Button/Button';

function Navbar() {
    const [mobileMenuClicked, setMobileMenuClicked] = useState(false);

    const handleClick = () => {
        setMobileMenuClicked(!mobileMenuClicked)
    }
    
    const closeMobileMenu = () => setMobileMenuClicked(false);

    return (
        <nav className='navbar'>
            <Link to='/' className="brand">
                Apidae &lt;/&gt;
            </Link>
            <div className="menu-icon" onClick={handleClick}>
                <i className={mobileMenuClicked ? 'menu-icon-times' : 'menu-icon-burger'}></i>
            </div>
            <ul className={mobileMenuClicked ? 'nav-menu active' : 'nav-menu'}>
                { MenuItems.map((item, index) => {
                    return (
                        <li key={index} className='nav-item'>
                            <Button buttonStyle={`btn--transparent`} url={item.url} onClick={closeMobileMenu}>
                                {item.title}
                            </Button>
                        </li>
                    )
                })}
                <li className='nav-item'>
                    <Button buttonStyle='btn--outline' url='/contact' onClick={closeMobileMenu}>GET IN TOUCH</Button>
                </li>
            </ul>
        </nav>
    )
}

export default Navbar

