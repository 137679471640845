import React from 'react'

function ProjectPipsWeirdMirror() {
    return (
        <>
            <h1 className="projectsPipsWeirdMirror">Projects - Pip's Weird Mirror</h1>
        </>
    )
}

export default ProjectPipsWeirdMirror
