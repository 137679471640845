import React from 'react';
import Section from '../Section/Section';
import './InfoSection.css';

const InfoSection = ({ 
    title, 
    darkBackground = false, 
    imageOnRight = true, 
    heading,  
    button, 
    media,
    children
}) => {
    return (
        <Section className={`info-section ${darkBackground ? 'dark' : 'light'} ${imageOnRight ? '' : 'reverse'}`}>
    {title && (<h2>{title}</h2>)}
    <div className="content-wrapper">
        <div className="text-content">
            {heading && <h3>{heading}</h3>}
            {children && children}
            {button && (button)}
        </div>
        {media && (
            <div className="media-content">
                {media.type === 'image' ? (
                    <img src={media.src} alt={media.alt || ''} />
                ) : (
                    <video src={media.src} autoPlay loop muted/>
                )}
            </div>
        )}
    </div>
</Section>

    );
};

export default InfoSection;
