import React from 'react'

function ServicesCloud() {
    return (
		<>
			<h1 className="servicesCloud">SERVICES - CLOUD</h1>
		</>
    )
}

export default ServicesCloud
