import React from 'react';
import Section from '../Section/Section'; // Import the base Section component
import './CardSection.css';
import Card from '../Card/Card';

const CardSection = ({ title = 'Default Title', cards, darkBackground = false }) => {
    if (!cards || cards.length === 0) {
        return (
            <Section>
                <h2>No cards available.</h2>
            </Section>
        );
    }

    return (
        <Section className={`card-section ${ darkBackground ? 'dark' : 'light'}`}>
            <h2>{title}</h2>
            <div className="card-grid">
                {cards.map((card, index) => (
                    <Card key={index} 
                        darkBackground={!darkBackground} 
                        image={card.image} 
                        title={card.title} 
                        description={card.description} 
                        link={card.link}/>
                    ))
                }
            </div>
        </Section>
    );
};

export default CardSection;