import React from 'react'
import HeroSection from '../../HeroSection/HeroSection'
import InfoSection from '../../InfoSection/InfoSection'
import { Button } from '../../Button/Button'

function ProjectAntsteroids() {

    const iosButton = (
        <Button
            buttonStyle="btn--ios"
            buttonSize="btn--medium"
            url="https://apps.apple.com/us/app/antsteroids/id123456789"
        >
        </Button>
    )

    return (
        <>
            <HeroSection
                title="Antsteroids!"
                subTitle="MASOCORE ARTHROPOGEDON"
                imageUrl="/images/AntsteroidsCap1.png"
            />
            <InfoSection
                title='Project Details'
                darkBackground={true}
                heading='Overview'
                imageOnRight={true}
                bodyCopy='Antsteroids! is a quirky 2D endless space shooter. You shoot space ants and collect artefacts and new vessels as you progress. It is fast-paced, unforgiving, and downright weird. Version 2 available soon :)'
                button={iosButton}
                media={{type:"image", src:"/images/AntsteroidsCap2Square.png", alt:"something alt"}}
            />
            <InfoSection
                darkBackground={false}
                heading='Features'
                imageOnRight={false}
                bodyCopy={
                    <ul>
                        <li>6 collectable ship types including the <em>House</em>, a <em>plAnt</em>, and some <em>pAnts</em></li>
                        <li>12 collectable artefacts that affect gameplay when equipped... No-one knows what they do though :/</li>
                        <li>5 mission types including <em>Get to the place!</em> and <em>Get that thing!</em></li>
                        <li>endless starfeilds to navigate</li>
                        <li><em>New enemies coming soon</em></li>

                    </ul>
                }
                media={{type:"image", src:"/images/AntsteroidsCap2Square.png", alt:"something alt"}}
            />
            <InfoSection
                darkBackground={true}
                heading='Tools'
                imageOnRight={true}
                bodyCopy={
                    <ul>
                        <li>IDE: Xcode</li>
                        <li>Workflow: Xcode Cloud, Git, TestFlight</li>
                        <li>Libraries/Frameworks: Foundation, UIKit, Spritekit, AVFoundation, AVKit</li>
                        <li>Audio Assets: Reason</li>
                        <li>Visual Assets: Pixen</li>

                    </ul>
                }
                media={{type:"image", src:"/images/AntsteroidsCap2Square.png", alt:"something alt"}}
            />
        </>
    )
}

export default ProjectAntsteroids
