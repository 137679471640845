import React from 'react'

function ProjectIllTechnique() {
    return (
        <>
            <h1 className="projectsIllTechnique">Projects - Ill Technique</h1>
        </>
    )
}

export default ProjectIllTechnique
