import React, {useState, useEffect} from 'react';
import Section from '../Section/Section';
import './HeroSection.css';

function HeroSection({title = 'Hero Title', subTitle = 'Hero Subtitle', imageUrl }) {

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setOffset(window.scrollY);
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Section
            className="hero-section"
            style={{
                backgroundImage: `url(${imageUrl})`,
                backgroundPosition: `center ${offset * 0.5 - 50}px`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundColor: 'black'
            }}
        >
            <h1>{title}</h1>
            <p>{subTitle}</p>
        </Section>
    )
}

export default HeroSection
