import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

function Card({ key, darkBackground, image, title, description, link}) {
  return (
        <Link to={link} key={key} className={`card ${darkBackground ? 'dark' : 'light'}`}>
            {image && <img src={image} alt={title || 'Card'} />}
            {title && <h3>{title}</h3>}
            {description && <p>{description}</p>}
        </Link>
    )
}

export default Card
